import React from 'react'
import StyledHero from './styles'
import { StaticImage } from 'gatsby-plugin-image'
import { SocialList, RepoCard } from '../common'
import TypeIt from 'typeit-react'

const Hero = () => {
  const res1avatar = 'https://placekitten.com/100/100'
  const res1name = 'ann-benchmarks'
  const res1author = 'erikbern'
  const res1description =
    'Approximate Nearest Neighbour Search Benchmark. Our method <b>qsgngt</b> achieves top performant on most datasets ! 🌟'

  const res2avatar = 'https://placekitten.com/105/105'
  const res2name = 'big-ann-benchmarks'
  const res2author = 'harsha-simhadri'
  const res2description =
    'We participated in NeurIPS\'23 with Huawei Theor. Lab and achieved good results in Streaming & Filter tracks ! 🖥️'

  const res3avatar = 'https://placekitten.com/110/110'
  const res3name = 'Awesome-Cross-Modal-Retrieval'
  const res3author = 'khylonwong'
  const res3description = 'A repository of Cross-Modal Retrieval papers (still updating). 📖'

  return (
    <StyledHero>
      <section>
        <div className="hero-welcome">
          <h1 className="flex flex-col justify-center space-y-10">
            <div>
              <span className="greeting">
                Hi there
                <span role="img" aria-label="Hand wave emoji" className='hand-emoji'>
                  👋
                </span>{' '}
              </span>
              I'm <span className="gatsby">Khylon</span>
            </div>
            <span className="greeting">
              Feel free to look around !
              <span role="img" aria-label="hello emoji">
                😊
              </span>{' '}
            </span>
            <div className="flex">
              <SocialList />
            </div>
          </h1>
          {/* <div className="my-img-wrapper ">
            <StaticImage className='rounded-md shadow-lg' src="../../images/me.jpg" alt="Profile picture" />
          </div> */}

          <div className="my-img-wrapper group relative w-96">
            <StaticImage
              className="rounded-md shadow-lg default-img transition-opacity duration-500 ease-in-out opacity-100 group-hover:opacity-0 absolute inset-0"
              src="../../images/me.jpg"
              alt="Profile picture"
            />
            <StaticImage
              className="rounded-md shadow-lg hover-img transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100"
              src="../../images/me_wake.jpg"
              alt="Profile picture on hover"
            />
          </div>
        </div>
        {/* <p>
          Hello, my name is Khylon Wong and it's my pleasure to welcome you to my personal webpage. I'm currently
          working towards my Ph.D. degree at{' '}
          <a href="https://ilearn.qd.sdu.edu.cn/" target="_blank" rel="noopener noreferrer">
            Intelligent Media Research Center (iLearn)
          </a>{' '}
          ,{' '}
          <a href="https://www.sdu.edu.cn/" target="_blank" rel="noopener noreferrer">
            Shandong University(SDU)
          </a>{' '}
          . Prior to pursuing my doctoral studies, I completed my B.S. degrees in Computer Science and Technology from
          the same university in 2022. I find great joy in taking on creative challenges and enjoy cultivating
          meaningful relationships as I work towards my goals. If you're interested in learning more, please feel free
          to peruse my history below and don't hesitate to contact me directly. <span>{'😉'}</span>
        </p> */}

        <div className="flex justify-center h-28 mt-6 text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-blue-500">
          <div className="mt-6">
            <TypeIt
              className="text-xl my-2"
              options={{ loop: true, speed: 80 }}
              getBeforeInit={(instance) => {
                instance
                  .type('Those times when you get up early and you work hard,')
                  .pause(600)
                  .type(' those times when you stay up late and you work hard,')
                  .pause(600)
                  .type(" those times when you don't feel like working,")
                  .pause(600)
                  .type(" you're too tired and you don't want to push yourself,")
                  .pause(600)
                  .type(' but you do it anyway.')
                  .pause(600)
                  .type(' That is actually the dream.')
                  .pause(600)
                  .type(" That's the dream.")
                  .pause(600)
                  .type(" It's not the destination, it's the journey. ")
                  .pause(6000)
                return instance
              }}
            />
          </div>
        </div>

        {/* <div className="flex justify-center">
          <div className="w-full mt-10 border-b-2 border-gray-500"></div>
        </div>

        <div className="flex justify-center">
          <h2 className="text-2xl font-bold">Repositories</h2>
        </div>

        <div className="flex justify-center mt-10">
          <RepoCard avatar={res1avatar} author={res1author} name={res1name} description={res1description} />
          <RepoCard avatar={res2avatar} author={res2author} name={res2name} description={res2description} />
          <RepoCard
            avatar={res3avatar}
            author={res3author}
            name={res3name}
            description={res3description}
            title="CMR Paper List"
          />
        </div> */}
      </section>
    </StyledHero>
  )
}

export default Hero
